<template>
<div>
  <HeaderWrp/>
  <div class="my_container">
    <Breadcrumbs>
      <router-link to="/">{{ $t('courseInner.btnPath.home') }}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
          <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <router-link to="/about">{{ $t('courseInner.btnPath.category') }}</router-link>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
          <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
          <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
        </svg>
        <router-link class="curr" to="/about">{{ titleCourse }}</router-link>
        <template v-slot:title>
          <h2>{{ titleCourse }}</h2>
        </template>
    </Breadcrumbs>
    <div class="course__inner">
      <div class="video__cnt">
        <img ref="mainPreview" src="/img/course-video.jpg" alt="post-image" v-show="playBtn">  
          <!-- <iframe src="" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" id="selectVideo" ref="selectVideo"></iframe>     -->
         <vimeo-player class="iframe-vue" ref="player" :video-id="videoID" @timeupdate="progress" @ended="changeProgressVideo(100)" @ready="onReady = true"></vimeo-player>
        <span class="play__btn" v-show="playBtn" @click="selectVideo(0)">
          <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.5 20.732C32.8333 19.9622 32.8333 18.0378 31.5 17.268L3.75 1.24648C2.41666 0.476677 0.75 1.43893 0.75 2.97853V35.0215C0.75 36.5611 2.41667 37.5233 3.75 36.7535L31.5 20.732Z" fill="white"/>
          </svg>
        </span>
      </div>
      <div class="video__col">
        <div class="top">
          <strong>{{ $t('courseInner.courseMaterials') }}</strong>
          <div v-if="false" class="duration">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#000001"/>
              <path d="M8 9C8 9 8.002 5.25 8 4V9ZM8 9H13Z" fill="white"/>
              <path d="M8 9H13M8 9C8 9 8.002 5.25 8 4V9Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>1h 20 min.</span>
          </div>
        </div>
        <div class="videos">

          <div class="video__link"
           v-for="(item, ind) in video"
           :key="ind + Math.random() * (100 - 1) + 1"
           :class="{ 'watched' : item.watched, 'active' : item.status }"
           @click="selectVideo(ind)"
          >
            <span class="img__cont">
              <img :src="item.poster" alt="poster-image">
              <span class="play__btn">
                <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.5 20.732C32.8333 19.9622 32.8333 18.0378 31.5 17.268L3.75 1.24648C2.41666 0.476677 0.75 1.43893 0.75 2.97853V35.0215C0.75 36.5611 2.41667 37.5233 3.75 36.7535L31.5 20.732Z" fill="white"/>
                </svg>
              </span>
            </span>
            <span class="details">
              <span class="name">{{ item.title }}</span>
              <span class="btm">
                <span class="count">{{ ind+1 }}/{{video.length}}</span>
                <div class="duration">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#000001"/>
                    <path d="M8 9C8 9 8.002 5.25 8 4V9ZM8 9H13Z" fill="white"/>
                    <path d="M8 9H13M8 9C8 9 8.002 5.25 8 4V9Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>{{ item.time }}</span>
                </div>
              </span>
            </span>
          </div>

        </div>
      </div>
    </div>
    <div class="row_banners">
      <div class="baner" :style="{ backgroundImage: `url(${baner.image})` }" v-for="(baner) in banners" :key="baner.id + Math.random() * (100 - 1) + 1">
      </div>
    </div>
    <div class="course__content__wrp">
      <div class="course__content">
        <div class="fig">
          <svg width="70" height="50" viewBox="0 0 70 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M70 0H0L69.9996 50L70 0Z" fill="white"/>
            <path d="M0 50H70L0.000427246 0L0 50Z" fill="#000001"/>
          </svg>
        </div>
        <div class="top">
          <h2>{{ $t('courseInner.courseTitle.text') }} <b>{{ $t('course.courseTitle.color') }}</b></h2>
          <div class="right">
            <div class="dsc">{{ $t('courseInner.courseDesc') }}</div>
            <div class="btm">
              <div class="card">
                <div class="num">
                  {{ total_theoretical_video }}
                </div>
                <span>{{ $t('courseInner.chapters') }}</span>
              </div>
              <div class="card">
                <div class="num">
                  {{ total_practical_video }}
                </div>
               <span>{{ $t('courseInner.modules') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content__col">

          <div class="card" 
            v-for="card in courseContent" :key="card.id + Math.random() * (100 - 1) + 1"
            :class="{ 'active': card.status }"
            @click="card.status = !card.status"    
          >
            <div class="title">
              <strong>{{ card.name }}</strong>
              <div class="btn"></div>
            </div>

            <p class="inner-p" v-html="card.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterApp/>
</div>
</template>

<script>
import LangRu from '@/lang/courseInner/ru'
import LangRo from '@/lang/courseInner/ro'
import LangEng from '@/lang/courseInner/eng'
import { vueVimeoPlayer } from 'vue-vimeo-player'

import HeaderWrp from "@/components/HeaderWrp"
import Breadcrumbs from "@/components/Breadcrumbs";
import FooterApp from "@/components/FooterApp";
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins:[LangRu, LangRo, LangEng],
  components: {
    HeaderWrp,
    Breadcrumbs,
    FooterApp,
    'vimeo-player' : vueVimeoPlayer
  },
  async created(){
    await this.getVideo();
    if(this.video.length){
      try {
        await this.$refs.player.update(this.video[0].video);
      }
      catch(e) {
        try {
          await this.$refs.player.update(this.video[0].video);
        }
        catch(ex) {
          await this.$refs.player.update(this.video[0].video);
        }
      }
      
      this.videoID = this.video[0].video;
    }
  },
  updated(){
    // this.$refs.mainPreview.src = this.video[0].poster;
  },
  data: () => ({
    video: [],
    courseContent: [],
    playBtn: true,
    titleCourse: '',
    total_practical_video: 0,
    total_theoretical_video: 0,
    banners: [],
    videoID: '453421514',
    access_to_courses: '',
    full_progress: 0,
    selectedVideo: {},
    completion_certificate: [],
    response: {},
    onReady: false
  }),
  computed: {
    ...mapGetters({ 
      getCourse : 'courses/getOne',
      lang: 'languages/getLang',
      user: 'user/user'
    }),
    selectLang() {
      if(this.lang == 'ro'){
        return this.langRo;
      }
      else if(this.lang == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    }
  },
  methods: {
    async selectVideo(ind) {
      // try {
      //   await this.$refs.player.pause();
      // }
      // catch(er){
      //   try{
      //     console.warn('error pause()');
      //     await this.$refs.player.pause();
      //   }
      //   catch(e){
      //     console.warn('error pause()');
      //     await this.$refs.player.pause();
      //   }
       
      // }
      console.log(this.video[ind].video);
      if(this.onReady){
        this.video.forEach((v) => {
          v.status = false;
        });
        this.video[ind].status = true;
        try {
          await this.$refs.player.update(this.video[ind].video);
        }
        catch(ex){
          try {
            console.warn('error update()');
            await this.$refs.player.update(this.video[ind].video);
          }
          catch(e){
            console.warn('error update()');
            await this.$refs.player.update(this.video[ind].video);
          }
        }
        this.videoID = this.video[ind].video;
        this.selectedVideo = this.video[ind];
        
        // this.$refs.player.autoplay = true;
        this.playBtn = false;
        try {
          await this.$refs.player.play();
        }
        catch(ex){
          try {
            console.warn('error play()');
            await this.$refs.player.play();
          }
          catch(e){
            console.warn('error play()');
            await this.$refs.player.play();
          }
        }
      }
    },
    async getVideo(){
      try {
        const response = await fetch(`${domain}/${this.lang}/courses/access_list/${this.$route.params.id}/`,{
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        if(!response.ok){
          console.error("error request server courses/access_list");
          this.$router.push({name: 'Home', params: {lang: this.lang}});
        }
        else {
          const data = await response.json();
          this.response = data;
          
          this.total_practical_video = data.course.total_practical_video;
          this.total_theoretical_video = data.course.total_theoretical_video;
          
          this.titleCourse = data.course.name;
          this.banners = data.course.stock_for_course;
          
          this.completion_certificate = data.course.completion_certificate;
          this.access_to_courses = data.id;
          this.full_progress = Number(data.full_progress);
          this.video = wrappCourse(data.course.courses_video);
    
          data.access_courses_video_info.forEach(infoElem => {
            let ind = this.video.findIndex(el => el.id == infoElem.video); 
            if(ind != -1){
              this.video[ind].watched = infoElem.watched;
              this.video[ind].how_many_viewed = Number(infoElem.how_many_viewed);
              this.video[ind].idUpdate = infoElem.id;
            }
          });   
          this.video[0].status = true;
          this.courseContent = data.course.course_content;
          // let blob = await fetch(this.completion_certificate[0].image).then(r => r.blob()); // convert to file
        }
  
        function wrappCourse(arr) {
          const course = [];
          function getIdVideo(url) {
            let result = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
            return result[0].split('/')[1];
          }
          arr.forEach(e => {                
            course.push({
              id: e.id,
              course_video: e.course_video,
              idUpdate: '',
              poster: e.image_thumbnail,
              title: e.title,
              time: e.time,
              status: false,
              video: `${getIdVideo(e.video_link)}`,
              watched: false,
              how_many_viewed: 0
              // video: `https://player.vimeo.com/video/${getIdVideo(e.video_link)}`
            });
          });
  
          return course;
        }
      }
      catch(ex){
        console.error(ex);
      }
    },
    progress(e){
      let pr = Math.floor(Number(e.percent * 100));
      if(pr > this.selectedVideo.how_many_viewed + 10){ 
        this.selectedVideo.how_many_viewed = pr;
        this.changeProgressVideo(pr)
      }
    },
    async changeProgressVideo(pr){
      let progress = pr >= 90 ? 100 : pr;
      try {
        if(this.selectedVideo.idUpdate){
          await fetch(`${domain}/courses/access-course-video/${this.selectedVideo.idUpdate}/`, {
            method: 'PATCH',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Token ${this.user.token}`
            },
            body: JSON.stringify({
              watched: progress >= 100,
              how_many_viewed: progress
            })
          });
          this.selectedVideo.watched = progress >= 100;
          this.selectedVideo.how_many_viewed = progress;
         
         await this.ChangeFullProgressCourse();

        }
        else {
          let req = await fetch(`${domain}/courses/access-course-video/`, {
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Token ${this.user.token}`
            },
            body: JSON.stringify({
              access_to_courses: this.access_to_courses,
              video: this.selectedVideo.id,
              watched: progress >= 100,
              how_many_viewed: progress
            })
          });
   
          let resp = await req.json();
          this.selectedVideo.watched = progress >= 100;
          this.selectedVideo.how_many_viewed = progress;
          this.selectedVideo.idUpdate = resp.id;
         

          await this.ChangeFullProgressCourse();
        }
       
      }
      catch(er){
        console.error(er);
      }
    },
    async ChangeFullProgressCourse(){   
      let count = 0;
      this.video.forEach(el => {
        count += el.how_many_viewed;
      });

      let summ  = count / this.video.length;
    
    
      if(summ != this.full_progress){
        this.full_progress = summ >= 100 ? 100 : summ;
        await fetch(`${domain}/courses/access_list/${this.access_to_courses}/`, {
          method: 'PATCH',
          headers:{
            'Content-Type': 'application/json',
            Authorization: `Token ${this.user.token}`
          },
          body: JSON.stringify({
            full_progress: Math.trunc(this.full_progress),
            watched:  this.full_progress >= 100,
            lang: this.lang     
          })
        });
      }
    }
  },
  watch: {
    lang() {
      this.getVideo();
    }
  }
}
</script>

<style lang="scss">
  .course__content__wrp {
    margin: auto;
    padding-bottom: 50px;
  }
  .course__inner .video__cnt::after {
    display: none;
  }
  .video__link,.play__btn {
    cursor: pointer;
  }
  #selectVideo {
    border-radius: 12px;
    background-color: black;
  }
  .iframe-vue {
    width: 100% !important;
    height: 100% !important;
    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  @media (min-width:1300px) {
    .row_banners {
      max-width: 1728px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 100px;

      .baner {
        width: 100%;
        max-width: 300px;
        height: 170px;
        border-radius: 12px;
        background-position: center;
        background-size: cover;
      }
    }
    .course__inner {
      margin-bottom: 110px !important;
    }
  }
  @media(min-width: 431px) {
    .course__inner  {

      .video__cnt {
        height: 644px !important;
        align-self: center;
      }
      .video__col {
        height: 644px !important;
        .videos {
          border-radius: 12px;
        }
      }
    }
    .course__content .content__col .card p {
      font-size: 18px !important;
    }
  }
</style>