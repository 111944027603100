import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Layouts from '@/Layouts.vue'
import Home from '../views/Home.vue'
import About from '@/views/About.vue'
import Category from '@/views/Category.vue'
import ShopItem from '@/views/ShopItem.vue'
import Author from '@/views/Author.vue'
import CourseInner from '@/views/CourseInner.vue'
import Login from '@/views/Login.vue'
import Registration from '@/views/Registration.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Courses from '@/views/dashboard/Courses.vue'
import Orders from '@/views/dashboard/Orders.vue'
import Certificates from '@/views/dashboard/Certificates.vue'
import Protocols from '@/views/dashboard/Protocols.vue'
import MyPage from '@/views/dashboard/MyPage.vue'
import Viber from '@/views/Viber.vue'


Vue.use(VueRouter)

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
let lng = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'ro';

if(locale == 'ru' || locale == 'ro' || locale == 'en' || locale == 'fr'){
  lng = locale;
}




const routes = [
  {
    path: '/:lang(ro|ru|en|fr)',
    component: Layouts,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'login',
        name: 'login',
        component: Login 
      },
      {
        path: 'viber',
        name: 'viber',
        component: Viber
      },
      {
        path: 'registration',
        name: 'Registration',
        component: Registration 
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      {
        path: 'category',
        name: 'category',
        component: Category
      },
      {
        path: 'shop-category',
        name: 'shopCategory',
        meta: { auth: true },
        component: () => import('@/views/ShopCategory.vue')
      },
      {
        path: 'shop/:id',
        name: 'shop',
        component: () => import('@/views/Shop.vue')
      },
      {
        path: 'shop-item/:id',
        name: 'shop-item',
        meta: { auth: true },
        component: ShopItem
      },
      {
        path: 'product/:user/:id',
        name: 'product',
        component: () => import('@/views/Product.vue')
      },
      {
        path: 'author',
        name: 'author',
        component: Author
      },
      {
        path: 'expert/:id',
        name: 'expert',
        component: () => import('@/views/Expert.vue')
      },
      {
        path: 'checkout',
        name: 'сheckout',
        component: () => import('@/views/Checkout.vue')
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('@/views/Payment.vue')
      },
      {
        path: 'checkout-success',
        name: 'checkout-success',
        component: () => import('@/views/CheckoutSuccess.vue')
      },
      {
        path: 'course/:id',
        name: 'course',
        component: () => import('@/views/Course.vue')
      },
      {
        path: 'course-inner/:id',
        name: 'CourseInner',
        component: CourseInner,
        meta: { auth: true },
      },
      {
        path: 'useful-info/:id',
        name: 'usefulInfo',
        component: () => import('@/views/UsefulInfo.vue'),
        meta: { auth: true },
      },
      {
        path: 'reset/:token',
        name: 'reset',
        component: () => import('@/views/Reset.vue')
      },
      {
        path: 'blog/',
        name: 'blog',
        component: () => import('@/views/Blog.vue')
      },
      {
        path: 'article/:id',
        name: 'article',
        component: () => import('@/views/Article.vue')
      },
      {
        path: 'promotions',
        name: 'promotions',
        component: () => import('@/views/Promotions.vue')
      },
      {
        path: 'promotion/:id',
        name: 'promotion',
        component: () => import('@/views/Promotion.vue')
      },
      {
        path: 'dashboard',
        component: Dashboard,
        meta: { auth: true },
        children: [
          {
            path: 'courses',
            name: 'courses',
            component: Courses
          },
          {
            path: 'orders',
            name: 'orders',
            component: Orders
          },
          {
            path: 'requests-list',
            name: 'requestsList',     
            component: () => import('@/views/dashboard/RequestsList.vue')
          },
          {
            path: 'certificates',
            name: 'certificates',
            component: Certificates
          },
          {
            path: 'protocols',
            name: 'protocols',
            component: Protocols
          },
          {
            path: 'useful-info',
            name: 'useful-info',
            component: () => import('@/views/dashboard/UsefulInfo.vue')
          },
          {
            path: 'change-pass',
            name: 'change-pass',
            component: () => import('@/views/dashboard/ChangePass.vue')
          },
          {
            path: 'my-page',
            name: 'my-page',
            component: MyPage
          },
          {
            path: 'shop',
            name: 'dashboard-shop',
            component: () => import('@/views/dashboard/Shop.vue')
          }
        ]
      },
      { 
        path: '*', 
        redirect: {name: 'Home'} 
      }
    ]
  },
  { 
    path: '*', 
    // redirect: '/ro' 
    redirect: (to) => { return `/${lng}` + to.path }
  }
];


const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: "active", 
  linkExactActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if(!to.matched.some(route => route.meta.auth)) {
      return { x: 0, y: 0 }
    }
  }
});

function hasQueryParams(route){
    return !!Object.keys(route.query).length
}


router.beforeEach((to, from, next) => {
 if (to.matched.some(route => route.meta.auth) && !store.getters['user/isLogin']){
    next({ name: 'login' });
 }
 else {
   next();
 }
 if(!hasQueryParams(to) && hasQueryParams(from)){
   next({name: to.name, params: to.params, query: from.query});
 }
 else {
   next()
 }
});

export default router
