<template>
  <div ref="policeModal" class="politica_modal" :style="{'opacity': hidden ? 0 : 1}">
    <div class="left_message">
      <p class="message">
        {{$t('politics.message')}}
      </p>
    </div>
    <div class="buttons_wrap">
      <a v-if="user && user.country && user.country.countryCode !== 'RO'" class="link_police" target="_blank" :href="`/policy_md/policy_${langStorage}.pdf`"> {{$t('politics.link')}}</a>
      <a v-else class="link_police" target="_blank" :href="`/cookie_ro/cookie_ro.pdf`"> {{$t('politics.link')}}</a>
      
      <a class="check_police" @click="check">{{$t('politics.btn')}}</a>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  data(){
    return {
      hidden : false,
      translateText: {
        message: {
          ru: 'Мы используем cookie-файлы для нашего сайта для того, чтобы обеспечить наилучший способ подбора Ваших предпочтений. Нажав на кнопку "Принимаю" Вы соглашаетесь с использованием cookie-файлов. Перейдите в Настройки cookie-файлов, чтобы узнать больше.',
          ro: 'Folosim cookie-uri pe site-ul nostru web pentru a vă oferi cea mai relevantă experiență prin colectarea preferințelor Dvs și repetarea vizitelor. Accesând butonul ”Accept” Dvs dați acord la utilizarea cookie-urilor. Accesați Setările Cookie-urilor pentru a afla mai multe destpre cookie-urile utilizate pe site.',
          en: 'We use cookies on our website in order to provide the best way to tailor your preferences. By clicking on the "I Agree" button you agree to the use of cookies. Go to Cookie Settings to find out more.'
        },
        btn:{
          ro: 'Accept',
          ru: 'Принимаю',
          en: 'I Agree'
        },
        link:{
          ro: 'Politica cookies',
          ru: 'Политика безопасности',
          en: 'Security policy'
        }   
      }
    }
  },
  mounted(){
    let police = window.localStorage.getItem('police');
    if(police){
      this.hidden = true;
      this.$refs.policeModal.style.display = "none"
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    })
  },
  methods:{
    check(){
      this.hidden = true;
      window.localStorage.setItem('police', true);
      setTimeout(() => {
        this.$refs.policeModal.style.display = "none"
      }, 1000);
      
    }
  }
}
</script>
<style lang="scss">
  .politica_modal {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 192px;
    background-color: #515076;
    z-index: 5;
    transition: 0.5s;
    .left_message {
      width: 100%;
      max-width: 1087px;
      margin-left: 160px;
      .message {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: white;
      }
    }
    .buttons_wrap {
      display: flex;
      align-items: center;
      margin-right: 98px;
      margin-left: auto;
      padding-left: 80px;
      .link_police {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        color: white;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 33px;
        transition: 0.5s;
        white-space: nowrap;
        &:hover {
          color: #c978c1;      
        }
      }
      .check_police {
        border: 1px solid rgba(255, 255, 255, 0.25); 
        box-sizing: border-box;
        border-radius: 96px;
        padding: 21px 48px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: white;
        text-decoration: none;
        cursor: pointer;
        transition: 0.5s;
        white-space: nowrap;
       

        &:hover {
          color: #c978c1;
          border: 1px solid #A34599; 
        }
      }
    }
  }
  @media(max-width: 1024px){
    .politica_modal {
      flex-direction: column;
      min-height: 116px;
      .left_message {
        margin: 0;
        padding: 12px 16px;
        .message {
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          margin: 0;
        }
      }
      .buttons_wrap {
        padding: 0;
        margin: 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;

        .link_police {
          font-weight: 300;
          font-size: 12px;
          margin-right: 0;
        }
        .check_police {
          font-weight: 600;
          font-size: 12px;
          padding: 10px 25px;
          margin-right: 19px;
        }
      }
    }
  }
</style>