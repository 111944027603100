<template>
  <div class="fixed__cart" :class="{ 'active': productBasket.scrolMenu && totalCnt }" >
    <div class="cnt">
      <router-link class="left" to="/checkout">{{titleCart}}</router-link>
      <div class="right">
        <router-link class="details" to="/checkout">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.733 11.1465C32.595 10.9471 32.4108 10.7841 32.196 10.6715C31.9813 10.5589 31.7425 10.5001 31.5 10.5H10.9995L9.2685 6.34501C9.04153 5.79784 8.6572 5.33039 8.16424 5.00192C7.67127 4.67346 7.09187 4.49877 6.4995 4.50001H3V7.50001H6.4995L13.6155 24.5775C13.7295 24.8507 13.9218 25.0841 14.1681 25.2483C14.4145 25.4124 14.7039 25.5 15 25.5H27C27.6255 25.5 28.185 25.1115 28.4055 24.528L32.9055 12.528C32.9905 12.3009 33.0193 12.0566 32.9892 11.816C32.9592 11.5754 32.8713 11.3457 32.733 11.1465Z" fill="white"/>
            <path d="M15.75 31.5C16.9926 31.5 18 30.4926 18 29.25C18 28.0074 16.9926 27 15.75 27C14.5074 27 13.5 28.0074 13.5 29.25C13.5 30.4926 14.5074 31.5 15.75 31.5Z" fill="white"/>
            <path d="M26.25 31.5C27.4926 31.5 28.5 30.4926 28.5 29.25C28.5 28.0074 27.4926 27 26.25 27C25.0074 27 24 28.0074 24 29.25C24 30.4926 25.0074 31.5 26.25 31.5Z" fill="white"/>
          </svg>
          <span class="count">
            {{ totalCnt }}
          </span>
          <span class="price">
            {{ currencyPrice }} {{currency}}
          </span>
        </router-link>
        <router-link class="order__btn" to="/checkout">{{btnTitle}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data(){
    return {
      productBasket: {
        scrolMenu: false
      }
    }
  },
  created(){
    if(window.innerWidth >= 768){
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if(scrollTop >= 850){
        this.productBasket.scrolMenu = true;
      }
      else {
        this.productBasket.scrolMenu = false;
      }
    }
  },
  computed: {
    ...mapGetters({ 
      totalSum: 'checkout/totalSum',
      totalSumRo: 'checkout/totalSumRo',
      totalSumRu: 'checkout/totalSumRu',
      totalCnt: 'checkout/totalCnt',
      langStorage : 'languages/getLang',
      user: 'user/user',
    }),
    titleCart(){
      return this.langStorage == 'ro' ? 'Coș de cumpărături' : this.langStorage == 'ru' ? 'Корзина покупателя' : 'Shopping cart';
    },
    btnTitle(){
      return this.langStorage == 'ro' ? 'Plasează comanda' : this.langStorage == 'ru' ? 'Сделайте заказ' : 'Make an order';
    },
    currency(){
      if(this.user.profile?.profile.city == "Moldova, Republic of"){
        return "MDL"
      }
      else if(this.user.profile?.profile.city == "Romania") {
        return "LEI"
      }
      else{
        return "RUB"
      }
    },
    currencyPrice(){
      if(this.user.profile?.profile.city == "Moldova, Republic of"){
        return Number(this.totalSum.toFixed(1))
      }
      else if(this.user.profile?.profile.city == "Romania"){
        return Number(this.totalSumRo.toFixed(1))
      }
      else{
        return Number(this.totalSumRu.toFixed(1))
      }
    }
    
  }
}
</script>

<style lang="css">
  .shop__cnt,.fixed__cart .cnt {
    padding: 0 20px;
  }
</style>