<template>
<div class="container">
  <div class="flag note note--success">
    <div class="flag__image note__icon">
      <i class="fa fa-check"></i>
    </div>
    <div class="flag__body note__text">
      <slot>{{$t('confirmation.text')}}</slot>
    </div>
    <a style="cursor:pointer" class="note__close" @click="$emit(`close`)">
      <i class="fa fa-times"></i>
    </a>
  </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data(){
    return {
      textTranslate: {
        ro: {
         confirmation: 'Salvare reușită'
        },
        ru: {
          confirmation: 'Успешное сохранение'
        },
        en: {
          confirmation: 'Successful save'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      langStorage: 'languages/getLang'
    })
  }
}
</script>
<style lang="scss" scoped>
@import url(https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:700,400,300);

$spacing: 24px;

/**
* Set up reusable colors
*/
$main-color:           #9b59b6;
$secondary-color:      #34495e;
/**
* Colors from Flat UI
* Source: http://flatuicolors.com
*/
$pomegranate:          #c0392b;
$alizarin: 			      #e74c3c;
$pumpkin: 			       #d35400;
$carrot: 			        #e67e22;
$orange:               #f39c12;
$sunflower:            #f1c40f;
$emerald:              #2ecc71;
$nephritis:            #27ae60;
$turquoise:            #1abc9c;
$green-sea:            #16a085;
$peter-river:          #3498db;
$belize-hole:          #2980b9;
$amethyst:             #9b59b6;
$wisteria:             #8e44ad;
$clouds:               #ecf0f1;
$silver:               #bdc3c7;
$concrete:             #95a5a6;
$asbestos:             #7f8c8d;
$wet-asphalt:          #34495e;
$midnight-blue:        #2c3e50;


* { box-sizing: border-box; }

.container {
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  z-index: 5;
  transition: 0.5s;
}


/**
* This can be extended to anything to make it get slightly bigger on hover,
* which is a pretty commonly desired effect
*/
.big-on-hover {
  transform: scale(1, 1);
  &:hover {
    transform: scale(1.2, 1.2);
  }
}


/**
* Reusable single-direction margin declaration
*/
.margin {
  margin-top: 0;
  margin-bottom: $spacing;
  &:last-child {
    margin-bottom: 0;
  }
}

p {
  @extend .margin;
}

/**
* Flag Object
* Original Source: https://csswizardry.com/2013/05/the-flag-object/
*/
.flag {
  @extend .margin;
  display: table;
  width: 100%;
}

.flag__image,
.flag__body {
  display: table-cell;
  vertical-align: middle;
  .flag--top & {
    vertical-align: top;
  }
  .flag--bottom & {
    vertical-align: bottom;
  }
}

.flag__image {
  padding-right: $spacing;
  > img {
    display: block;
    max-width: none;
  }
  .flag--rev & {
    padding-right: 0;
    padding-left: $spacing;
  }
}

.flag__body {
  width: 100%;
}

  
/**
* Notification Styles
*/
.note {
  position: relative;
  overflow: hidden;
  color: white;
  background-color: $main-color;
}

.note--secondary { background-color: $secondary-color; }
.note--success   { background-color: $nephritis; }
.note--warning   { background-color: $orange; }
.note--error     { background-color: $alizarin; }
.note--info      { background-color: $peter-river; }

  .note__icon,
  .note__text {
    padding: $spacing;
  }

  .note__icon {
    min-width: ($spacing * 2) + 32px;
    text-align: center;
    font-size: 32px;
    font-size: 2rem;
    background-color: rgba(black, 0.25);
  }

  .note__text {
    padding-right: $spacing * 2;
  }

  .note__close {
    //@extend .big-on-hover;
    position: absolute;
    top: $spacing / 2;
    right: $spacing / 2;
    width: 29px;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    color: white;
    background-color: rgba(black, 0.25);
    opacity: 0;
    transition: all 0.25s;
    &:hover {
      background-color: rgba(black, 0.15);
    }
    .note:hover & {
      opacity: 1;
    }
  }

</style>