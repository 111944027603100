<template>
<div :class="{'grey' : colorBack}">
  <HeaderWrp/>
  <div class="dashboard__menu" :class="{ 'active' : mobileDashboard.mode }" @click="mobileDashboard.mode = !mobileDashboard.mode">
    <div class="top">
      <span>{{ mobileDashboard.title }}</span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2178 16.4405C12.0983 16.5631 11.9013 16.5631 11.7819 16.4405L3.54039 7.98174C3.42527 7.86357 3.42527 7.67519 3.54039 7.55703C3.65984 7.43444 3.85685 7.43444 3.97629 7.55703L12.2178 16.0158C12.3329 16.134 12.3329 16.3223 12.2178 16.4405Z" fill="#930684"/>
        <path d="M20.4599 7.55703C20.3405 7.43444 20.1435 7.43444 20.024 7.55703L11.7801 16.0183C11.665 16.1364 11.665 16.3248 11.7801 16.443C11.8996 16.5656 12.0966 16.5656 12.216 16.443L20.4599 7.98173C20.5751 7.86357 20.5751 7.67519 20.4599 7.55703Z" fill="#930684"/>
      </svg>
    </div>
    <div class="col">
      <div v-for="(item, index) in menu" :key="index" style="margin-bottom: 40px;">
        <router-link v-if="!item.hidden" class="link" :to="{ name: item.name }">
          <span @click="mobileDashboard.title = menu[index].title[langStorage]"> {{ item.title[langStorage] }}</span>
        </router-link> 
        <a v-else :class="item.class" style="color: #64697a" :data-title="item.dataTitle[langStorage]">{{ item.title[langStorage] }}</a>
      </div>
      <a @click="logOut" class="log__out">{{translateExit}}</a>
    </div>
  </div>
  <div class="my_container">
    <div class="dashboard__cnt">
      <div class="user__col">
        <div class="details">
          <div class="img__cont">
            <img :src="userResponce.profile.image == null ? '/img/avatar-dashboard.png': userResponce.profile.image ">
          </div>
          <div class="about">
            <div class="name">{{ user.userName }}</div>
            <div class="function">{{ user.specialization }}</div>
          </div>
        </div>
        <div class="links">
          <div class="link-item" v-for="(item, index) in menu" :key="index" @click="onShortcut($event.target, item.name)">
            <router-link                        
              :to="{ name: item.name }"
              v-if="!item.hidden"
            >
              {{ item.title[langStorage] }} 
            </router-link>
            <a v-else :class="item.class" style="color: #64697a" :data-title="item.dataTitle[langStorage]">{{ item.title[langStorage] }}</a>
            <svg v-if="item.icon && item.hidden" width="15px" height="15px">
              <use :xlink:href="item.icon"></use>
            </svg>
            <img v-else-if="item.Shortcut" :src="item.Shortcut"  alt=""> 
          </div>
        </div>
        <a @click="logOut" class="log__out">{{ translateExit }}</a>
      </div>
      <router-view></router-view>
    </div>
  </div>
  <CalendarModalAdd 
    :class="{'active': modalCalendarAdd}"
    :selectUser="selectUser"
    v-scroll-lock="modalCalendarAdd"
    @warning="checkModal = true"
    @close="CloseModalCalendarAdd"
    @reload="reload"
  />
  <CheckModal class="checkm-modal" :class="{'active': checkModal}" @close="checkModal = false">
    <template v-slot:header>
      {{$t('warnings')}}
    </template>
    <template v-slot:body>
      <div class="inp__row">
        <strong>{{$t('dashboard.applications.warnings')}}</strong>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer">
      
      </div>
    </template>
  </CheckModal>
  <FooterApp/>
</div>
</template>

<script>
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp";
import CalendarModalAdd from '@/components/CalendarModalAdd'
import CheckModal from '@/components/CheckModal'
import { mapGetters, mapActions } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    HeaderWrp,
    FooterApp,
    CalendarModalAdd,
    CheckModal
  },
  data(){
    return {
      mobileDashboard: {
        mode : false,
        title: 'Menu'
      },
      menu:[
        {
          title: {
            ro: 'Cursuri procurate',
            ru: 'Купленные курсы',
            en: 'Purchased courses',
            fr: 'Cours achetés'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          class: '',
          Shortcut: '',
          icon: '',
          name : 'courses',
          hidden : false
        },
        {
          title: {
            ro: 'Comenzile mele',
            ru: 'Мои заказы',
            en: 'My orders',
            fr: 'Mes commandes'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          class: '',
          Shortcut: '',
          icon: '',
          name : 'orders',
          hidden : false,
        },
        {
          title: {
            ro: 'Cererile clienților',
            ru: 'Заявки',
            en: 'Customer requests',
            fr: 'Demandes'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          class: '',
          Shortcut: '/img/add.png',
          icon: '',
          name : 'requestsList',
          hidden : false,
        },
        {
          title: {
            ro: 'Certificate',
            ru: 'Сертификаты',
            en: 'Certificates',
            fr: 'Certificats'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          class: '',
          Shortcut: '',
          icon: '',
          name : 'certificates',
          hidden : false,
        },
        {
          title: {
            ro: 'Protocoale',
            ru: 'Протоколы',
            en: 'Protocols',
            fr: 'Protocoles'
          },
          class: '',
          Shortcut: '',
          icon: '',
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          name: 'protocols',
          hidden: false
        },
        {
          title: {
            ro: 'Informații utile',
            ru: 'Полезная информация',
            en: 'Useful info',
            fr: 'Infos pratiques'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          Shortcut: '',
          icon: '',
          class: '',
          name: 'useful-info',
          hidden: false
        },
        {
          title: {
            ro: 'Schimbă-ți parola',
            ru: 'Изменить пароль',
            en: 'Change password',
            fr: 'Changer le mot de passe'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          Shortcut: '',
          icon: '',
          class: '',
          name: 'change-pass',
          hidden: false
        },
        {
          title: {
            ro: 'Pagina mea',
            ru: 'Моя страница',
            en: 'My page',
            fr: 'Ma page'
          },
          dataTitle: {
            ro: 'Disponibil la achiziționarea cursului',
            ru: 'Доступно при покупке курса',
            en: 'Available when purchasing the course'
          },
          Shortcut: '',
          icon: '/icons.svg#lock',
          class: 'my_page',
          name: 'my-page',
          hidden: true
        },
        {
          title: {
            ro: 'Cashback',
            ru: 'Cashback',
            en: 'Cashback',
            fr: 'Cashback'
          },
          dataTitle: {
            ro: '',
            ru: '',
            en: ''
          },
          Shortcut: '',
          icon: '',
          class: '',
          name: 'dashboard-shop',
          hidden: false
        }

      ],
      modalCalendarAdd: false,
       selectUser: {
        id: '',
        name: '',
        phone: '',
        date: '',
        time: 'HH:mm',
        duration: '00:30',
        comment: '',
        confirmation: false,
      },
      userResponce: {
        profile:{
          image: null
        }
      },
      checkModal: false
    }
  },
  async beforeMount(){
    await this.checkList();
    await this.getUser();
  },
  watch: {
    async modalCalendarAdd() {
      console.log("open popup add schedule");
    }
  },
  computed:{
    ...mapGetters({
      darkMode: 'darkMode',
      langStorage : 'languages/getLang',
      user: 'user/user',
    }),
    colorBack(){
     if(this.darkMode){
       return true;
     }
      return false;
    },
    translateExit(){
      return this.langStorage == 'ro' ? 'Ieși din cabinet' : this.langStorage == 'ru' ? 'Выйти' : 'Log out';
    }
  },
  methods: {
    ...mapActions({
      editUser: 'user/user'
    }),
    async logOut(){      
      await fetch(`${domain}/auth/token/logout/`, { 
       method: 'POST',
       headers: {
        Authorization: `Token ${this.user.token}`
       }
      });
      this.editUser({name:'token', 'value': ''});
      this.editUser({name:'profile', 'value': null});
      this.$router.push("/");
    },
    onShortcut(target, name){
      if(target.classList == 'link-item' && name == 'requestsList') {
        this.modalCalendarAdd = true;
      }
    },
    resetUser(){
      this.selectUser = {
        id: '',
        name: '',
        phone: '',
        date: '',
        time: 'HH:mm',
        duration: '00:30',
        comment: '',
        confirmation: false,
      }
    },
    reload(){
     if(this.$route.name == "requestsList"){
      location.reload();
     }
      this.resetUser();
    },
    CloseModalCalendarAdd(){
      this.modalCalendarAdd = false;
      this.resetUser();
    },
    async checkList() {
      try {
        // const response = await fetch(`${domain}/${this.langStorage}/courses/access_list/`, { 
        //   headers: { 
        //     Authorization: `Token ${this.user.token}`
        //   } 
        // });
        // if(!response.ok){
        //   throw 'responce error server (Dashboard page)';
        // }
        // const data = await response.json();

        let myPage = this.menu.find(m => m.name == "my-page");
        myPage.hidden = false;

      }
      catch(er){
        console.error(er);
      }
    },
    async getUser(){
      try {
        const req = await fetch(`${domain}/accounts/users/${this.user.userID}/`);
        const res = await req.json();
        this.userResponce = res;
      }
      catch(er) {
        console.error(er);
      }
    },
    myDate(day){
      let d = new Date();
      if(day){
        d.setDate(d.getDate() + day);
      }
      return `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1): (d.getMonth() + 1) }-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
    },
  }

}
</script>

<style lang="scss">
.night {
  .dashboard__cnt .user__col .details {
    background: #141418;
  }
  .dashboard__menu {
    .top {
      background: #141418;
      border-color: #930684;
    }
    .col {
      background: #141418;
      .link {
        &:not(.active) {
          color: white;
        }
      }
      .log__out {
        color: white;
      }
    }
  }
}
.grey {
  background: #f5f4fb;
}
@media (max-width: 430px){
  .dashboard__menu{
    margin-top: 118px;
  }
}
.dashboard__menu .col {
  min-height: 100vh;
  overflow: auto;
  & .link.active {
    color: #930684;
  }
}
.log__out {
  cursor: pointer;
}
.dashboard__cnt{
  margin-top: 105px;
}
.dashboard__cnt .user__col .links {
  a {
    margin-bottom: 0;
  }
  .link-item {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    align-items: center;
    margin-bottom: 40px;
    &:hover {
      img {
        opacity: 0.4;
      }
    }
    
    img {
      width: 15px;
      height: 15px;
      margin-left: 15px;
      opacity: 0;
      transition: 0.3s;
    }
  }
  .link-item {
    svg {
      margin-left: 10px;
      fill: rgb(100, 105, 122);
    }
  }
}

.my_page {
  display: block;;
  position: relative;

  &:hover::after {
    content: attr(data-title);
    position: absolute;
    background-color: #EFEEFD;
    width: 200px;
    padding: 8px;
    border-radius: 12px;
    font-size: 13px;
    line-height: normal;
    color: black;
    top: 0;
    left: 115px;
    z-index: 1;
  }
}
</style>