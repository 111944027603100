<template>
  <div class="faq__wrp">
    <div class="txt">
      <h3>{{ $t('faq.title.text') }} <b>{{ $t('faq.title.color') }}</b></h3>
      <p>{{ $t('faq.desc.text') }}<b>{{ $t('faq.desc.color') }}</b></p>
      <p>{{ $t('faq.desc.text2') }}</p>
<!--      <div class="fig">-->
<!--        &lt;!&ndash; <img src="img/faq-fig.svg" alt=""> &ndash;&gt;-->
<!--      </div>-->
    </div>
    <div class="col">
      <div class="faq__card" v-for="(item, index) in faq" :key="index" :class="{ 'active': item.isOpen }">
        <div class="top" @click="openItem(index)">
          <strong>{{ item.title }}</strong>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3263 24.6607C18.1471 24.8446 17.8516 24.8446 17.6724 24.6607L5.31022 11.9726C5.13753 11.7954 5.13753 11.5128 5.31022 11.3356C5.48939 11.1517 5.78491 11.1517 5.96407 11.3356L18.3263 24.0237C18.499 24.2009 18.499 24.4835 18.3263 24.6607Z" fill="#000001"/>
            <path d="M30.6895 11.3355C30.5104 11.1517 30.2148 11.1517 30.0357 11.3355L17.6699 24.0274C17.4972 24.2046 17.4972 24.4872 17.6699 24.6645C17.849 24.8483 18.1445 24.8483 18.3237 24.6645L30.6895 11.9726C30.8622 11.7954 30.8622 11.5128 30.6895 11.3355Z" fill="#000001"/>
          </svg>
        </div>
        <span>{{ item.desc }}</span>
      </div>

      <div class="btm">
        <span>{{ $t('faq.info') }}</span>
        <a class="req__btn" v-scroll-to="{ el: '.objective__cnt', offset: -110 }">
          {{ $t('faq.btnText') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LangRo from '@/lang/Faq/ro';
import LangRu from '@/lang/Faq/ru';
import LangEng from '@/lang/Faq/eng';

export default {
  mixins:[LangRo, LangRu, LangEng],
  data() {
    return {
     
    }
  },
  mounted() {
    this.initializeFaq();
  },
  watch: {
    getLang(){
      //изменился язык на сайте
      this.initializeFaq();
    }
  },
  methods: {
    ...mapActions({
      initializeFaq: 'faq/setFaq',
      useItem: 'faq/useItem'
    }),

    openItem(index){
      //this.faq[index].isOpen = !this.faq[index].isOpen;
      this.useItem(index);
    },
  },
  computed: {
    ...mapGetters({
      getLang: 'languages/getLang',
      faq: 'faq/getFaq'
    }),
    
    lang() {
      if(this.getLang == 'ro'){
        return this.langRo;
      }
      else if(this.getLang == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    }
    
  }
}
</script>

<style lang="scss">
  .faq__wrp {
    .req__btn {
      white-space: nowrap;
      cursor: pointer;
    }
  }
  @media (max-width: 1024px) and (min-width: 430px) {
    .faq__wrp {
      margin: 50px auto;
    }
  }
</style>