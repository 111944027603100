<template>
<div class="about_page">
  <HeaderWrp/>
  <div class="my_container">
    <Breadcrumbs>
      <router-link :to="{name: 'Home'}">{{$t('about.breadCrumbs.home')}}</router-link>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
        <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
        <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
      </svg>
      <a class="curr" style="cursor:pointer">{{$t('about.breadCrumbs.about')}}</a>
    </Breadcrumbs>
  </div>
  <div class="my_container">
    <div class="about__txt">
      <div class="top">
        <strong>{{$t('about.left')}}</strong>
        <span>{{$t('about.right')}}</span>
      </div>
      <div class="banner about_banner">
        <img src="/img/about-banner.jpg" alt="">
      </div>
    </div>

    <AboutAuthor 
    v-if="AboutTheAuthor.length" 
    :title="AboutTheAuthor[0].title"
    :name="AboutTheAuthor[0].sub_title"
    :desc="AboutTheAuthor[0].description_short"
    :images="AboutTheAuthor[0].gallery_about_author"
    :background="false"
    />
  </div>
  <div class="objective__wrp">
  <ContactForm/>  
  </div>
  <FaqWrp/>
  <FooterApp/>
</div>
</template>
<script>
import LangRu from '@/lang/About/ru' 
import LangRo from '@/lang/About/ro' 
import LangEng from '@/lang/About/eng'

import HeaderWrp from "@/components/HeaderWrp";
import FooterApp from "@/components/FooterApp";
import Breadcrumbs from "@/components/Breadcrumbs";
import FaqWrp from "@/components/FaqWrp"
import VueSlickCarousel from 'vue-slick-carousel'
import ContactForm from '@/components/ContactForm'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import AboutAuthor from '@/components/AboutAuthor'

import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins:[LangRu, LangRo, LangEng],
  components: {
    HeaderWrp,
    FooterApp,
    FaqWrp,
    VueSlickCarousel,
    ContactForm,
    AboutAuthor,
    Breadcrumbs
  },
  data: ()=> ({
    AboutTheAuthor: []
  }),
  metaInfo() {
    return this.langStorage == 'ru' ? {
      title: 'Lica Doctor - интернациональная академия красоты',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Lica Doctor - интернациональная академия красоты'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Академия красоты Lica Doctor подготавливает профессионалов в области косметологии и омоложения. Обучение проводится на профессиональной косметике ведущих мировых брендов'
        }
      ]
    } :
    this.langStorage == 'ro' ? {
      title: 'Lica Doctor - Academia internațională de frumusețe',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Lica Doctor - Academia internațională de frumusețe'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'Academia de frumusețe Lica Doctor pregătește profesioniști în domeniul cosmetologiei și întineririi. Instruirea se desfășoară folosind produse cosmetice profesionale de la mărci de top din lume'
        }
      ]
    } :
    {
      title: 'Lica Doctor - international beauty Academy',
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: 'Lica Doctor - international beauty Academy'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The Lica Doctor Beauty Academy prepares professionals in the field of cosmetology and rejuvenation. Training is carried out using professional cosmetics from leading world brands'
        }
      ]
    }
  },
  beforeMount() {
    this.getAboutTheAuthor()
  },
  watch: {
    langStorage(){
      this.getAboutTheAuthor();
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    }),
    // selectLang() {
    //   if(this.langStorage == 'ro'){
    //     return this.langRo;
    //   }
    //   else if(this.langStorage == 'ru'){
    //     return this.langRu;
    //   }
    //   return this.langEng;
    // }
  },
  methods: {
    async getAboutTheAuthor(){
      const response = await fetch(`${domain}/${this.langStorage}/about-author-slider/`);
      if(response.ok){
        this.AboutTheAuthor = await response.json();
      }
    }
  }
}
</script>

<style lang="scss">
  // .about__txt .banner{
  //   background-image: url('/img/about-banner.jpg');
  //   height: 500px;
  //   background-size: cover;
  //   width: 100%;
  //   background-repeat: no-repeat;
  // }

// @media (min-width: 1025px) and (max-width: 1800px){
//   .about__txt .banner{
//     height: 330px !important;
// }
// }
@media (min-width: 1200px){
  .about__txt .about_banner{
    width: 60%;
  }
.about__txt .top span{
  font-size: 24px !important;
  //line-height: 37px !important;
}
  .about__txt .top strong{
    font-size: 26px !important;
    //line-height: 55px !important;
  }
}

  // .about__txt .banner img{
  //   height: 500px;
  // }
  @media(max-width: 430px){
    .about__txt .top strong{
      font-size: 25px !important;
    }
  }
  @media (max-width: 1024px) and (min-width: 430px) {
    .about__txt .top strong {
      font-size: 28px;
      //line-height: 40px;
    }
    .about__txt {
      margin: 0px auto 100px;
      .top span {
        font-size: 20px;
        //line-height: 30px;
      }
    }
    .about_page {
      .about__author {
        margin-bottom: 100px;
      }
    }
    .about__txt .banner {
      height: auto;
      img {
        object-fit: contain;
      }
    }
  }
</style>