<template>
<div class="dashboard__col">
  <!-- <h1>{{$t('dashboard.abonament.title') }}</h1> -->
  <div class="subscriptions__row" v-show="false">
    <div class="card">
      <div class="type">
        {{$t('courses.free')}}
      </div>
      <div class="advantage">
        {{$t('courses.advantage')}}
      </div>
      <div class="price">
        <strong>{{$t('courses.price')}}</strong>
        <span>{{$t('courses.perMonth')}}</span>
      </div>
      <a href="#" class="buy__btn current">
        {{$t('courses.current')}}
      </a>
      <div class="advantages">
        <span>{{$t('courses.advantage1')}}</span>
        <span>{{$t('courses.advantage2')}}</span>
        <span>{{$t('courses.advantage3')}}</span>
      </div>
    </div>
    <div class="card">
      <div class="type">
        {{$t('courses.type')}}
      </div>
      <div class="advantage">
        {{$t('courses.advantage4')}}
      </div>
      <div class="price">
        <strong>{{$t('courses.price2')}}</strong>
        <span>{{$t('courses.perMonth')}}</span>
      </div>
      <a href="#" class="buy__btn">
        {{$t('courses.buy')}}
      </a>
      <div class="advantages">
        <span>{{$t('courses.advantage5')}}</span>
        <span>{{$t('courses.advantage6')}}</span>
        <span>{{$t('courses.advantage3')}}</span>
      </div>
      <div class="additional__line">
        <div class="line"></div>
        <div class="plus">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8.625V15.5H8.625V8.625H15.5V7.375H8.625V0.5H7.375V7.375H0.5V8.625H7.375Z" fill="#000001"/>
          </svg>
        </div>
        <div class="line"></div>
      </div>
      <div class="add__adv">
        <div class="li">
          <div class="check">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.69653 8.95944L1.43164 5.8986L1.94459 5.35143L4.6797 7.91558L10.0479 2.54736L10.5783 3.07766L4.69653 8.95944Z" fill="white"/>
            </svg>
          </div>
          <span>{{$t('courses.work')}}</span>
        </div>
        <div class="li">
          <div class="check">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.69653 8.95944L1.43164 5.8986L1.94459 5.35143L4.6797 7.91558L10.0479 2.54736L10.5783 3.07766L4.69653 8.95944Z" fill="white"/>
            </svg>
          </div>
          <span>{{$t('courses.resource')}}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="type">
        {{$t('courses.type2')}}
      </div>
      <div class="advantage">
        {{$t('courses.advantage8')}}
      </div>
      <div class="price">
        <strong>{{$t('courses.price3')}}</strong>
        <span>{{$t('courses.perMonth')}}</span>
      </div>
      <a href="#" class="buy__btn">
        {{$t('courses.buy2')}}
      </a>
      <div class="advantages">
        <span>{{$t('courses.advantage10')}}</span>
        <span>{{$t('courses.advantage2')}}</span>
        <span>{{$t('courses.advantage3')}}</span>
      </div>
      <div class="additional__line">
        <div class="line"></div>
        <div class="plus">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8.625V15.5H8.625V8.625H15.5V7.375H8.625V0.5H7.375V7.375H0.5V8.625H7.375Z" fill="#000001"/>
          </svg>
        </div>
        <div class="line"></div>
      </div>
      <div class="add__adv">
        <div class="li">
          <div class="check">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.69653 8.95944L1.43164 5.8986L1.94459 5.35143L4.6797 7.91558L10.0479 2.54736L10.5783 3.07766L4.69653 8.95944Z" fill="white"/>
            </svg>
          </div>
          <span>{{$t('courses.work')}}</span>
        </div>
        <div class="li">
          <div class="check">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.69653 8.95944L1.43164 5.8986L1.94459 5.35143L4.6797 7.91558L10.0479 2.54736L10.5783 3.07766L4.69653 8.95944Z" fill="white"/>
            </svg>
          </div>
          <span>{{$t('courses.resource')}}</span>
        </div>
        <div class="li">
          <div class="check">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.69653 8.95944L1.43164 5.8986L1.94459 5.35143L4.6797 7.91558L10.0479 2.54736L10.5783 3.07766L4.69653 8.95944Z" fill="white"/>
            </svg>
          </div>
          <span>{{$t('courses.advice')}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="bought__courses">
    <!-- <h2>{{ selectLang.purchasedCourses }}</h2> -->
    <div class="card" v-for="(course) in courses" :key="course.idParent">
      <router-link :to="{name : 'CourseInner', params: {id : course.idParent, lang: langStorage}}" class="img__cont">
        <img :src="course.img" alt="">
        <span class="overlay">
          <span class="play">
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 8.73205C12.3333 7.96225 12.3333 6.03775 11 5.26795L3.5 0.937821C2.16666 0.168021 0.5 1.13027 0.5 2.66987V11.3301C0.5 12.8697 2.16667 13.832 3.5 13.0622L11 8.73205Z" fill="white"/>
            </svg>
          </span>
          <span class="txt">{{$t('dashboard.abonament.btnPlay')}}</span>
        </span>
      </router-link>
      <div class="details">
        <router-link class="title" :to="{name : 'CourseInner', params: {id : course.idParent, lang: langStorage}}">{{ course.title }}</router-link>
        <div class="tags">
          <a style="cursor: pointer" v-for="(tag, index) in course.tags" :to="tag.link" :key="index">{{ tag.title }}</a>
        </div>
        <div class="progress">
          <div class="top">
            <div class="left">
              <div class="lessons">{{ course.lessons }}</div>
              <div class="duration">{{ course.duration }}</div>
            </div>
            <div class="right">
              <div class="status">{{ course.percentage >= 100 ? selectLang.viewStatus.final : course.percentage == 0 ? selectLang.viewStatus.start : selectLang.viewStatus.mid }}</div>
              <div class="percentage" :class="{ 'zero' :  course.percentage == 0 }">
                <svg v-if="course.percentage >= 100" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.25944 11.9459L1.90625 7.8648L2.59019 7.13523L6.237 10.5541L13.3947 3.39648L14.1018 4.10355L6.25944 11.9459Z" fill="white"/>
                </svg>
                <span>{{ course.percentage }}%</span>
              </div>
            </div>
          </div>
          <div class="bar">
            <div class="full" :style="{ 'width': course.percentage + '%'}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="!courses.length">
      <h2 class="available">{{$t('courses.noAvailable')}}
        <span @click="$router.push({name: 'category', params:{lang: langStorage}})">{{$t('courses.noAvailableLink')}}</span>
      </h2>
    </div>
  </div>
</div>
</template>

<script>
import LangRu from '@/lang/dashboard/abonement/ru' 
import LangRo from '@/lang/dashboard/abonement/ro' 
import LangEng from '@/lang/dashboard/abonement/eng'

import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins:[LangRu, LangRo, LangEng],
  beforeMount(){
    this.getCoursesList();
  },
  data(){
    return {
      courses: [],
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    }
  },
  methods: {
    async getCoursesList() {
      const response = await fetch(`${domain}/${this.langStorage}/courses/access_list/`, { 
        headers: { 
          Authorization: `Token ${this.user.token}`
        } 
      });
      function wrappCourses(arr) {
        const course = [];

        arr.forEach((e, ind) => {
          course.push({
            idParent: e.id,
            id: e.course.id,
            title: e.course.name,
            link: `/course-inner/${e.id}`,
            tags: [],
            img: e.course.image_thumbnail,
            lessons: `1/${e.course.total_video_count}`,
            duration: e.course.time,
            percentage: e.full_progress,
            textBtn: 'Continuă'
          });
          e.course.brand.forEach(obj => {
            course[course.length -1].tags.push({
              id: obj.id,
              title: obj.title,
              link: `/course-inner/${e.id}`,
            });
          });
        });

        return course;
      }

      if(!response.ok){
        alert('request error server courses/access_list')
      }
      else {
        const data = await response.json();
        this.courses = wrappCourses(data);
      }
    }
  },
  watch: {
    langStorage(){
      this.getCoursesList();
    }
  }
}
</script>

<style lang="scss">
  .dashboard__col .bought__courses .card .img__cont .overlay {
    .play,.txt {
      transition: 0.5s;
    }
    &:hover {
      .play {
        transform: scale(1.2);
      }
      .txt {
        color: rgb(219, 203, 203);
      }
    }
  }
  .dashboard__col .bought__courses {
    margin-top: 50px !important;
  }
  .dashboard__col {
    .available {
      font-size: 1rem !important;
      span {
        text-decoration: underline;
        cursor: pointer;
        color: #930f81;
      }
    }
  }
</style>
